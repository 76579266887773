import Loadable from "app/components/Loadable";
import { lazy } from "react";

const AppForm = Loadable(lazy(() => import("./forms/AppForm")));
const AppMenu = Loadable(lazy(() => import("./menu/AppMenu")));
const AppIcon = Loadable(lazy(() => import("./icons/AppIcon")));
const AppProgress = Loadable(lazy(() => import("./AppProgress")));
const AppRadio = Loadable(lazy(() => import("./radio/AppRadio")));
const AppTable = Loadable(lazy(() => import("./tables/AppTable")));
const AppSwitch = Loadable(lazy(() => import("./switch/AppSwitch")));
const AppSlider = Loadable(lazy(() => import("./slider/AppSlider")));
const AppDialog = Loadable(lazy(() => import("./dialog/AppDialog")));
const AppButton = Loadable(lazy(() => import("./buttons/AppButton")));
const AppCheckbox = Loadable(lazy(() => import("./checkbox/AppCheckbox")));
const AppSnackbar = Loadable(lazy(() => import("./snackbar/AppSnackbar")));
const AppAutoComplete = Loadable(lazy(() => import("./auto-complete/AppAutoComplete")));
const AppExpansionPanel = Loadable(lazy(() => import("./expansion-panel/AppExpansionPanel")));
const Sites = Loadable(lazy(() => import("app/views/master/sites/sites")));
const Plots = Loadable(lazy(() => import("app/views/master/plots/plots")));
const Users = Loadable(lazy(() => import("app/views/crm/users/users")));
const Brokers = Loadable(lazy(() => import("app/views/crm/brokers/brokers")));
const Enquiry = Loadable(lazy(() => import("app/views/crm/enquiry/addEnquiry")));
const Property = Loadable(lazy(() => import("app/views/crm/property/property")));







const materialRoutes = [

  { path: "/crm/brokers/brokers", element: <Brokers /> },
  { path: "/crm/users/users", element: <Users /> },
  { path: "/crm/enquiry/addEnquiry", element: <Enquiry /> },
  { path: "/crm/property/property", element: <Property/> },

  { path: "/master/sites/sites", element: <Sites /> },
  { path: "/master/plots/plots", element: <Plots /> },

  { path: "/material/table", element: <AppTable /> },
  { path: "/material/form", element: <AppForm /> },
  { path: "/material/buttons", element: <AppButton /> },
  { path: "/material/icons", element: <AppIcon /> },
  { path: "/material/progress", element: <AppProgress /> },
  { path: "/material/menu", element: <AppMenu /> },
  { path: "/material/checkbox", element: <AppCheckbox /> },
  { path: "/material/switch", element: <AppSwitch /> },
  { path: "/material/radio", element: <AppRadio /> },
  { path: "/material/slider", element: <AppSlider /> },
  { path: "/material/autocomplete", element: <AppAutoComplete /> },
  { path: "/material/expansion-panel", element: <AppExpansionPanel /> },
  { path: "/material/dialog", element: <AppDialog /> },
  { path: "/material/snackbar", element: <AppSnackbar /> }
];

export default materialRoutes;
